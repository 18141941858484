import { GatsbySeo } from 'gatsby-plugin-next-seo'
import AmbientalComponent from 'src/components/Institucional/SustentabilidadeBrasil/AmbientalComponent'

function Ambiental() {
  return (
    <>
      <GatsbySeo title="Institucional | Decathlon" />
      <AmbientalComponent />
    </>
  )
}

export default Ambiental
